.login-form-box {
    margin-top: 50px;
}

.login-form-top {
    overflow: hidden;
    background: rgba(233, 233, 233, 0.35);
    -moz-border-radius: 4px 4px 0 0;
    -webkit-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
}

.login-form-logo {
    display: block;
    margin: auto;
    padding: 25px 25px 30px 25px;
}

.login-form-top h3,
.login-form-top p {
    margin-top: 0;
    // padding-left: 25px;
    color: #ffffff;
    text-align: center;
}

.login-form-top p {
    opacity: 0.8;
    font-size: 16pt;
}

.login-form-bottom {
    padding: 25px 25px 30px 25px;
    background: rgba(233, 233, 233, 0.35);
    -moz-border-radius: 0 0 4px 4px;
    -webkit-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px;
    text-align: left;
    max-height: 245px;
    min-height: 245px;
}

.example-enter {
    opacity: 0.01;
}

.example-enter.example-enter-active {
    opacity: 1;
    transition: all 300ms ease-in;
}

.example-exit {
    opacity: 1;
}

.example-exit.example-exit-active {
    opacity: 0.01;
    // transition: opacity 300ms ease-in;
}

.reset-password-error-text {
    color: white;
    padding-left: 15px;
    padding-top: 2.5px;
    padding-bottom: 2.5px;
    margin-bottom: -15px;
    background-color: rgba(230, 50, 54, 0.58);
    border-radius: 2.5px;
}
