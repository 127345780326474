@import '../../../style/sass/variables/color.scss';
.requiredStar {
    color: $required-blue;
    font-weight: bold;
}

.flexed {
    display: flex;
    width: 100%;
}

.columnInput {
    flex-direction: column;
    .input {
        width: 100%;
    }
    .input:last-child {
        margin-top: 12px;
    }
}
.rowInput {
    flex-direction: row;
    .input {
        width: 50%;
    }
    .input:last-child {
        margin-left: 5px;
    }
}
