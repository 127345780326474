//**************************************************//
//* STYLING FOR TABLES                             *//
//**************************************************//

.motili-table {
    //border-collapse: separate;
    width: 100%;
    thead {
        border-bottom: solid 5px $motili-light-blue;
        border-top: solid 2px $light-gray;
        // background-color: $dark-navy;
        // color: $white;
        // font-family: $font-family-sans-serif;
    }
    // the following two lines determine height of table header
    thead > tr {
        height: 25px;
    }
    thead > tr > th {
        line-height: 25px;
    }
    // TODO: get these two styles to apply without !important
    tr th {
        border-bottom: 0 !important;
        font-size: 14px;
    }
    tr td {
        border-top: 0 !important;
        font-size: 13px;
    }
    .table-footer {
        background-color: white !important;
        td {
            border: 0;
        }
    }
}

.table > thead > tr > th,
.table > thead > tr > td,
.table > tbody > tr > th,
.table > tbody > tr > td,
.table > tfoot > tr > th,
.table > tfoot > tr > td {
    padding: 5px;
}

//**************************************************//
//* STYLING FOR LISTS                              *//
//**************************************************//
