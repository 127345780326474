.orders-table {
    .table-loading {
        height: 500px;
    }

    .table-loading > div {
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        top: 200px;
    }

    .width-11-percent {
        width: 11%;
    }

    .width-9-percent {
        width: 9%;
    }

    .width-7-percent {
        width: 7%;
    }
}
