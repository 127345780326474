.dropdown-menu {
    padding: 0;
}

.dropdown-menu > li.disabled > a {
    color: $medium-dark-gray !important;
}

.dropdown-menu > li > a {
    line-height: 2.5;
    color: $motili-light-blue !important;
    // border-bottom: solid thin #e5e5e5;
    cursor: pointer;
}

.dropdown-menu .divider {
    margin: 0;
}

.ellipsis-action,
.dropdown-menu-right {
    text-transform: uppercase;
    text-align: right;
}
