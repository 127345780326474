@import 'src/style/sass/variables/color.scss';
:root{
    --toastify-toast-width: 426px;
    --toastify-color-success: #{$light-green};
    --toastify-text-color-success: #{$green};
    --toastify-color-error: #{$alert-background};
    --toastify-text-color-error: #{$red};
    --toastify-color-warning: #{$warning-background};
    --toastify-text-color-warning: #{$yellow};
    --toastify-color-info: #{$active-blue};
    --toastify-text-color-info: #{$motili-light-blue};
}

//General
.Toastify__toast-container {
    font-size: 16px;
}
.Toastify__toast-icon {
    margin-right: 24px;
}
.Toastify__close-button {
    align-self: center;
}

//Success Alert
.Toastify__toast--success {
    border: 1px solid var(--toastify-text-color-success);
    .Toastify__close-button {
        color: var(--toastify-text-color-success);
    }
    .Toastify__progress-bar {
        background: var(--toastify-text-color-success);
    }
}

//Error Alert
.Toastify__toast--error {
    border: 1px solid var(--toastify-text-color-error);
    border-bottom: 5px solid var(--toastify-text-color-error);
    .Toastify__close-button {
        color: var(--toastify-text-color-error);
    }
}

//Info Alert
.Toastify__toast--info {
    border: 1px solid var(--toastify-text-color-info);
    .Toastify__close-button {
        color: var(--toastify-text-color-info);
    }
    .Toastify__progress-bar {
        background: var(--toastify-text-color-info);
    }
}

//Warning Alert
.Toastify__toast--warning {
    border: 1px solid var(--toastify-text-color-warning);
    .Toastify__close-button {
        color: var(--toastify-text-color-warning);
    }
    .Toastify__progress-bar {
        background: var(--toastify-text-color-warning);
    }
}