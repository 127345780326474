.loading-screen {
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading-screen-item {
    align-self: center;
}
