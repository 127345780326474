@import '../../../style/sass/variables/color.scss';

.input {
    background-color: #eeeeee !important;
    cursor: not-allowed !important;
}

.requiredStar {
    color: $required-blue;
    font-weight: bold;
}