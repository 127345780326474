.header {
    color: $dark-gray;
    border-bottom: none;
    padding-bottom: 0;
    margin: 25px 0 10px;
    //border-bottom-width: 3px;
    //border-bottom-color: $light-gray;
}

.header-with-breadcrumb {
    margin-top: -20px;
}

.header-breadcrumb-bar {
    background-color: #f5f5f5;

    position: absolute;
    left: 0;

    height: 45px;
    width: 100%;
}

.header-breadcrumb {
    background-color: #f5f5f5;
    position: relative;

    .breadcrumb {
        padding: 12px 0;
    }

    .breadcrumb > li + li:before {
        content: '> ' !important;
        color: #777777;
    }
}

.header h1 {
    text-align: left;
}

.header-sub-text {
    color: $medium-gray;
}

.header-border {
    border-bottom: solid;
    border-bottom-width: 2px;
    border-bottom-color: $light-gray;
    //padding-bottom: 19px; // This is based off a comparison of a header 'with sub nav'
    margin-bottom: 20px;
}

.header-sub-nav {
    list-style-type: none;
    border-bottom: solid;
    border-bottom-width: 2px;
    border-bottom-color: $light-gray;
    padding-left: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

// TODO: remove border (should only be available on active)

.header-sub-nav-item {
    display: inline;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-right: 15px;
    cursor: pointer;
}

.header-sub-nav-item-disabled {
    opacity: 0.5;
}

.header-sub-nav-item-active {
    color: #3f82a5;
    border-bottom: solid;
    border-bottom-width: 3px;
    border-bottom-color: $motili-light-blue;
}

// TODO: Style link to be motili med gray, remove the underline, on active motili light blue

.header-sub-nav-link {
    font-size: 14px;
    font-weight: 600;
    color: #333;
}

a.header-sub-nav-link:link,
a.header-sub-nav-link:visited,
a.header-sub-nav-link:active {
    text-decoration: none;
}

a.header-sub-nav-link:hover {
    color: #3f82a5;
}

.header-sub-nav-link-disabled {
    font-size: 14px;
    font-weight: 500;
    color: $medium-gray;
}
a.header-sub-nav-link-disabled:link,
a.header-sub-nav-link-disabled:visited,
a.header-sub-nav-link-disabled:active {
    text-decoration: none;
}
a.header-sub-nav-link-disabled:hover {
    cursor: default;
    color: $medium-gray;
    text-decoration: none;
}
