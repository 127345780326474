$tree-images-path: '~images/tree';

.table-with-tree {
    .table {
        table-layout: fixed;
        width: 100%;
    }
    .table tr td {
        max-width: calc(100vw - 1180px);
        min-width: 60px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .table thead tr th:nth-child(1) {
        width: 10px;
    }
    .table thead tr th:nth-child(2) {
        width: 100px;
    }
    .table thead tr th:nth-child(4) {
        width: 125px;
    }
    .table thead tr th:nth-child(6) {
        width: 200px;
    }
    .table thead tr th:nth-child(7) {
        width: 30%;
    }
}

.tree-row {
    display: flex;
    margin: -5px;
    min-width: 94px;
}

.t-e {
    display: inline-block;
    height: 29px;
    width: 29px;
}

.t-root {
    display: inline-block;
    height: 29px;
    width: 29px;
    background-image: url('#{$tree-images-path}/root.svg');
}

.t-root-only {
    display: inline-block;
    height: 29px;
    width: 29px;
    background-image: url('#{$tree-images-path}/root-only.svg');
}

.t-b {
    display: inline-block;
    height: 29px;
    width: 29px;
    background-image: url('#{$tree-images-path}/branch.svg');
}

.t-cb {
    display: inline-block;
    height: 29px;
    width: 29px;
    background-image: url('#{$tree-images-path}/c-branch.svg');
}

.t-cn {
    display: inline-block;
    height: 29px;
    width: 29px;
    fill: #5cb85c;
    background-image: url('#{$tree-images-path}/c-node.svg');
}

.t-cn-2 {
    display: inline-block;
    height: 29px;
    width: 29px;
    fill: #5cb85c;
    background-image: url('#{$tree-images-path}/c-node-2.svg');
}

.t-cpb {
    display: inline-block;
    height: 29px;
    width: 29px;
    background-image: url('#{$tree-images-path}/cp-branch.svg');
}

.t-cpn {
    display: inline-block;
    height: 29px;
    width: 29px;
    background-image: url('#{$tree-images-path}/cp-node.svg');
}

.table-with-tree-bsc {
    .table {
        table-layout: fixed;
        width: 100%;
    }
    .table tr td {
        max-width: calc(100vw - 1180px);
        min-width: 60px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .table thead tr th:nth-child(1) {
        width: 10px;
    }
    .table thead tr th:nth-child(2) {
        width: 80px;
    }
    .table thead tr th:nth-child(3) {
        width: 100px;
    }
    .table thead tr th:nth-child(5) {
        width: 200px;
    }
    .table thead tr th:nth-child(6) {
        width: 200px;
    }
    .table thead tr th:nth-child(7) {
        width: 200px;
    }
}
