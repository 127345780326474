// THIS FILE IS DECPRECATED, USE THE common/components/layout/layout.scss file for further style changes

@import '../../../../../style/sass/variables/color.scss';

.layout {
    .wrapper {
        height: 100%;
        .layout {
            min-height: calc(100vh - 160px);
        }
    }
    .motili-body {
        min-width: 1080px;
        max-width: 1440px;
        min-height: calc(100vh - 160px);
        margin: auto;
        padding-left: 25px;
        padding-right: 25px;
        padding-bottom: 100px;

        h1 {
            margin: 0;
        }
    }

    // Removes the set min widths for the content to 768 and removes the max width,
    //  so it fills the space of the .wrapper .layout container
    // We still want to keep the padding and margin for the content along with the height for the footer
    .responsive-motili-body {
        min-height: calc(100vh - 160px);
        min-width: 768px;
        margin: auto;
        padding-left: 25px;
        padding-right: 25px;
        padding-bottom: 40px;

        h1 {
            margin: 0;
        }
    }

    // Deprecated
    .motili-container {
        min-width: 1080px;
        max-width: 1440px;
        min-height: 90%;
        margin: auto;
        padding-left: 25px;
        padding-right: 25px;
        padding-bottom: 100px;

        h1 {
            margin: 0;
        }
    }
}
