@import '../../../style/sass/variables/color.scss';

.form-container {
    background-color: $super-light-gray;
    padding: 15px;
    border-radius: 2.5px;
    margin-bottom: 15px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 3px rgba(0, 0, 0, 0.24);
}

.form-container.no-background {
    background-color: white;
}
.form-container.editing {
    background-color: #edf8fc;
}

.form-section-header {
    height: 48px;
    display: flex;
    margin-bottom: 15px;
    .left {
        height: 100%;
        display: flex;
        align-items: flex-end;
        margin-right: 10px;
    }
    .image {
        height: 100%;
    }
    .right {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
    .header {
        font-size: 24px;
        margin-bottom: 10px;
        color: $dark-gray;
    }
    .underline {
        width: 100%;
        max-width: 400px;
    }
}

.form-section-header-with-required {
    height: 65px;
    display: flex;
    margin-bottom: 15px;
    .left {
        height: 100%;
        display: flex;
        align-items: flex-end;
        margin-right: 10px;
    }
    .image {
        height: 100%;
    }
    .right {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
    .header {
        font-size: 24px;
        margin-bottom: 10px;
        color: $dark-gray;
    }
    .underline {
        width: 100%;
        max-width: 400px;
    }
}

.requiredFieldsText {
    color: $required-blue;
    font-size: 14px;
    font-weight: bold;
    font-style: italic;
}

.form-section-header-with-subtitle {
    width: 100%;
    .title {
        font-size: 24px;
        font-weight: bold;
    }
    .subtitle {
        font-size: 18px;
    }
}

.form-row {
    display: flex;
    justify-content: space-between;
    margin: 15px 0 0 0;
}

.form-row-reverse {
    composes: form-row;
    flex-direction: row-reverse;
}

.form-container .form-row:first-child {
    margin: 0;
}

.form-row .form-row:first-child {
    margin: 0;
}

.form-row-reverse .form-row-reverse:first-child {
    margin: 0;
    flex-direction: row-reverse;
}

.form-element {
    flex: 1;
    margin: 0 10px 0 10px;
}

.form-element:first-child {
    margin-left: 0;
}

.form-element:last-child {
    margin-right: 0;
}

.flex-2 {
    flex: 2;
}

.flex-3 {
    flex: 3;
}

.form-element-separator {
    width: 2px;
    border-left: 2px solid $medium-gray;
}

.vertica-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.requiredText {
    margin-bottom: -7px;
    margin-top: 15px;
    font-weight: bold;
}

.requiredStar {
    color: $required-blue;
    font-weight: bold;
}

.formSectionToolbar {
    display: flex;
    width: 100%;

    .right {
        flex: 1;
    }

    .left {
        flex: 1;
        justify-content: end;
        margin-right: 0;
        margin-top: 5px;
    }
}
