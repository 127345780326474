@import '../../../style/sass/variables/color.scss';

@mixin value-component {
    background-color: $light-gray;
    display: inline-block;
    font-size: 0.9em;
    line-height: 1.4;
    margin-left: 5px;
    margin-top: 4px;
    vertical-align: top;
}

.valueComponent {
    @include value-component;
    color: black;
}

.valueComponentIsFixed {
    @include value-component;
    color: $medium-gray;
}

.valueComponentContainer {
    display: flex;
    min-height: 24px;
    max-height: 24px;
    height: 100%;
    padding: 0 8px;
    align-items: center;
}

.valueComponentContainer p {
    align-self: center;
    margin: 0;
    font-weight: bold;
}

.x {
    align-self: center;
    padding-right: 6px;
}

.x:hover {
    cursor: pointer;
}

.select div:first-child {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border-left: none;
}

.dropdownButton button {
    height: 34px;
    background-color: $navy;
    color: white;
}

.dropdownButton button:hover {
    background-color: $motili-light-blue;
}

.labelContainer {
    display: flex;
    justify-content: space-between;
}

.rendererComponent {
    padding-top: 8px;
    padding-left: 10px;
    cursor: pointer;
    font-weight: lighter;
}

.rendererComponent:hover {
    cursor: pointer;
    background-color: #eaf2fe;
}

.rendererComponent label {
    cursor: pointer;
    font-weight: normal;
}

.requiredStar {
    color: $required-blue;
    font-weight: bold;
}
