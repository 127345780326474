$icon-font-path: '~bootstrap-sass/assets/fonts/bootstrap/';

/* Bootstrap */
@import '~bootstrap-sass/assets/stylesheets/_bootstrap.scss';

/* Font Awesome */
@import '~font-awesome/scss/font-awesome.scss';

/* React-Toastify Alerts */
@import 'react-toastify/dist/ReactToastify.css';

@import '~react-virtualized/styles.css';

/* Variables */
@import './variables/color';

/* Base Motili Stylesheet */
@import './base/base';

/* Components */
@import './components/alerts';
@import './components/buttons';
@import './components/busy-spinner';
@import './components/controls';
@import './components/drop-down';
@import './components/forms';
@import './components/header';
@import './components/footer';
@import './components/orders-tables';
// @import './components/layout';
@import './components/loading-screen';
@import './components/navigation';
@import './components/tables';
@import './components/tables-DEPRECATED';
@import './components/tree';

/* Pages */
@import './pages/login';
@import './pages/manage';
@import './pages/contractorCompanyDetails';

/* Motili-UI */
@import '~@motili/ui/dist/assets/styles/thirdPartyStyles.css';

@import '~react-tooltip/dist/react-tooltip.css';
