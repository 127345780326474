.form-select-overrides {
    .Select-control {
        height: auto !important;

        .Select-placeholder {
            line-height: 34px !important;
            color: #999;
            padding-left: 12px !important;
        }

        .Select-value {
            line-height: 34px !important;
            padding-left: 12px !important;

            .Select-value-label {
                line-height: 34px !important;
            }
        }

        .Select-input {
            height: 32px !important;
        }
    }

    .is-disabled {
        .Select-control {
            background-color: #eeeeee !important;
            cursor: not-allowed;
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        }
    }

    .Select-menu-outer {
        z-index: 99 !important;
    }

    .Select-clear-zone {
        padding-bottom: 4px;
    }

    .Select-menu {
        .VirtualizedSelectOption {
            padding: 8px 12px !important;

            &:hover {
                background-color: rgba(0, 126, 255, 0.1);
            }
        }

        .VirtualizedSelectFocusedOption {
            background-color: transparent;
        }

        .Select-option {
            padding: 8px 12px !important;
        }
    }
}

.input-group .form-group {
    &:not(:first-child):not(:last-child) {
        .form-select-overrides {
            .Select-control {
                border-radius: 0;
            }
        }
    }

    .form-select-overrides {
        .Select.is-focused:not(.is-open) > .Select-control {
            border-color: #ccc;
            box-shadow: none;
        }
    }
}
