@import '../../../style/sass/variables/color.scss';

.dropdown {
    select {
        margin: 0 !important;
        &:disabled {
            background-color: $background-gray;
            opacity: 1;
        }
    }
}

.notSelected {
    select {
        color: #757575;
    }
}
